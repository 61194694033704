// stylelint-disable declaration-no-important

//
// Right side table of contents
//
.bd-toc {
  @supports (position: sticky) {
    position: sticky;
    top: 5rem;
    height: calc(100vh - 5rem);
    overflow-y: auto;
  }
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: .875rem;
}

.section-nav {
  padding-left: 0;
  border-left: 1px solid #eee;
  ul {
    padding-left: 1rem;
    ul {
      display: none;
    }
  }
}

.toc-entry {
  display: block;

  a {
    display: block;
    padding: .125rem 1.5rem;
    color: #99979c;

    &:hover {
      color: $blue;
      text-decoration: none;
    }
  }
}

//
// Left side navigation
//

.bd-sidebar {
  order: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 5rem;
      height: calc(100vh - 5rem);
      overflow-y: auto;
    }
    border-right: 1px solid rgba(0, 0, 0, .1);
  }

  @include media-breakpoint-up(md) {
    padding: 1.5rem 0;
    border-right: 1px solid rgba(0, 0, 0, .1);
  }

  @include media-breakpoint-up(xl) {
    flex: 0 1 320px;
  }
}

.bd-links {
  padding: 0;

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      max-height: calc(100vh - 5rem);
      overflow-y: auto;
    }
  }

  // Override collapse behaviors
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}


.bd-sidenav {
  display: none;
}

.bd-toc-link {
  display: block;
  padding: .5rem 1rem;
  @include media-breakpoint-up(md) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }
  color: $gray-800;

  &:hover {
    color: $primary;
  }
}

.bd-toc-item {
  &.active {
    background-color: $white;

    > .bd-toc-link {
      color: $primary;
      font-weight: 600;

      &:hover {
        background-color: transparent;
      }
    }

    > .bd-sidenav {
      display: block;
    }
  }
}

// All levels of nav
.bd-sidebar .nav {
  > li > a {
    display: block;
    padding: .25rem 1.5rem;
    font-size: 16px;
    color: $gray-800;
    &:hover {
      color: $primary;
      background-color: transparent;
    }
  }
  > .active > a,
  > .active:hover > a {
    font-weight: 600;
    color: $primary;
    background-color: transparent;
  }
}


