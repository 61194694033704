.bd-footer {
  background-color: $docs-dark;
  color: $white;

  @include media-breakpoint-up(sm) {
    text-align: left;
  }
  .team-logo {
    &--icon {
      width: 72px
    }
    &--text {
      font-size: 16px;
      line-height: 1rem;
      max-width: 160px;
      text-transform: uppercase;
    }
  }
  .small-prints {
    color: #00FFF8;
    font-weight: 600;
  }
}
